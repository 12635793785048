"use client";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { v4 } from "uuid";

import { event_type } from "@/hl-common/types/api/PrismaEnums";
import type { SkipCondition } from "@/hl-common/types/api/components/automations/SkipCondition";
import type { CardEntity } from "@/hl-common/types/api/entities/Cards";
import type {
  EventEntity,
  IngestEventRequest,
} from "@/hl-common/types/api/entities/Events";
import type { ModuleEntity } from "@/hl-common/types/api/entities/Modules";
import { CourseContext } from "@/utils/CourseContext";
import { EventContext } from "@/utils/EventContext";
import { getCompletedCardsByModule, getModule } from "@/utils/api/client";
import { getErrorMessage } from "@/utils/api/fetch";
import { useParams, useRouter } from "next/navigation";

export enum DisplayMode {
  CARD = "card",
  MODULE_COMPLETE = "module_complete",
  COURSE_COMPLETE = "course_complete",
}

export type CardSubmitFunc = (
  answer: any,
  correct: boolean,
  skip: boolean,
  retryable: boolean,
) => void;

const emptyCardSubmit: CardSubmitFunc = (
  answer,
  correct,
  skip,
  retryable,
) => {};

export type NextCard = () => void;
const emptyNextCard: NextCard = () => {};

export const ModuleContext = React.createContext({
  module: null as ModuleEntity | null,
  moduleError: "",
  moduleLoading: true,
  moduleProgress: 0,
  card: null as CardEntity | null,
  completedCardEvents: [] as IngestEventRequest[],
  loadModule: async (moduleId: number) => {},
  nextCard: emptyNextCard,
  handleCardSubmit: emptyCardSubmit,
  setCardIndex: (cardIndex: number) => {},
  displayMode: DisplayMode.CARD,
  setDisplayMode: (displayMode: DisplayMode) => {},
});

export const WithModule = ({ children }: { children: React.ReactNode }) => {
  const { replace } = useRouter();
  const { course, isCourseComplete, addCourseEvent } =
    useContext(CourseContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [module, setModule] = useState(null as ModuleEntity | null);
  const [completedCardEvents, setCompletedCardEvents] = useState(
    [] as IngestEventRequest[],
  );
  const [cardIndex, setCardIndex] = useState(0);

  // events
  const { addEvent } = useContext(EventContext);
  const [cardStart, setCardStart] = useState(new Date().toISOString()); // ISO timestamp

  // display mode adds handling for "transition" states like module_complete and course_complete
  const [displayMode, setDisplayMode] = useState(DisplayMode.CARD);

  const card: CardEntity | null = useMemo(() => {
    // avoid evaluating card stuff (like skipConditions) if we're not in card mode
    if (displayMode !== DisplayMode.CARD) {
      return null;
    }

    return module?.cards?.[cardIndex] ?? null;
  }, [displayMode, cardIndex, module?.cards]);

  const { course_id } = useParams<{ course_id: string }>();
  const courseId = Number.parseInt(course_id);

  // on cardIndex change....
  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to depend on cardIndex, even though the actual value isn't used in the effect.
  useEffect(() => {
    // scroll to top of page
    window.scrollTo(0, 0);
    // update card start time
    setCardStart(new Date().toISOString());
  }, [cardIndex]);

  // called from the module loader component
  const loadModule = useCallback(
    async (moduleId: number) => {
      setLoading(true);
      setError("");

      try {
        const [moduleResp, completedCardsResp] = await Promise.all([
          getModule({ params: { courseId, moduleId } }),
          getCompletedCardsByModule({ params: { courseId, moduleId } }),
        ]);

        if (moduleResp.data.cards?.length === 0) {
          throw new Error("The module is empty.");
        }

        setModule(moduleResp.data);
        setCompletedCardEvents(completedCardsResp.data);

        // Initalize the card index. Normally, we resume a module at the first uncompleted card.
        const idx = startingIndex(
          moduleResp.data.cards ?? [],
          completedCardsResp.data,
        );

        if (idx < (moduleResp.data.cards ?? []).length) {
          setCardIndex(idx);
        } else {
          // The user has already completed this module.
          if (moduleResp.data.repeatable) {
            // For repeatable modules, restart at the beginning.
            setCardIndex(0);
          } else {
            // Since the module is not repeatable, go back to the course overview.
            replace(courseId ? `/courses/${courseId}` : "/courses");
          }
        }
      } catch (error) {
        setError(getErrorMessage(error));
        setModule(null);
        setCompletedCardEvents([]);
        setCardIndex(0);
      }

      setLoading(false);
    },
    [replace, courseId],
  );

  const moduleProgress = useMemo(() => {
    if (displayMode !== DisplayMode.CARD) {
      return 1;
    }

    if (!module?.cards?.length) {
      return 0;
    }

    return cardIndex / module?.cards?.length;
  }, [cardIndex, module, displayMode]);

  // advance the index, or show appropriate completion screen if we just completed a module or course
  const nextCard = useCallback(() => {
    if (module?.cards?.length && cardIndex < module.cards.length - 1) {
      return setCardIndex(cardIndex + 1);
    }

    // end of module or course!
    if (module && isCourseComplete(module.id)) {
      setDisplayMode(DisplayMode.COURSE_COMPLETE);
    } else {
      setDisplayMode(DisplayMode.MODULE_COMPLETE);
    }

    // reset cardIndex
    setCardIndex(0);
  }, [cardIndex, module, isCourseComplete]);

  const handleCardSubmit = useCallback(
    (answer: any, correct: boolean, skip: boolean, retryable: boolean) => {
      const cardEnd = new Date();
      const duration = cardEnd.getTime() - new Date(cardStart).getTime();
      const cardCompleted = correct || skip || !retryable;

      // extra events
      const moduleBegin = cardIndex === 0;
      const moduleComplete = !!(
        module?.cards?.length &&
        cardIndex === module.cards.length - 1 &&
        cardCompleted
      );

      const courseComplete = moduleComplete && isCourseComplete(module.id);

      const event: IngestEventRequest = {
        uuid: v4(),
        courseId: course?.id,
        moduleId: module?.id,
        cardId: card?.id,
        type: event_type.card_submit,
        answer,
        correct,
        skip,
        retryable,
        timestamp: cardEnd.toISOString(),
        duration,
        extraEvents: {
          moduleBeginUuid: moduleBegin ? v4() : undefined,
          moduleCompleteUuid: moduleComplete ? v4() : undefined,
          courseCompleteUuid: courseComplete ? v4() : undefined,
        },
      };

      // send to the server
      addEvent(event);

      // save to CourseContext
      addCourseEvent(event);

      if (cardCompleted) {
        // save to completedCardEvents
        setCompletedCardEvents([...completedCardEvents, event]);
      }
    },
    [
      addEvent,
      addCourseEvent,
      course?.id,
      isCourseComplete,
      module,
      cardIndex,
      card,
      completedCardEvents,
      cardStart,
    ],
  );

  const skip = useCallback(() => {
    handleCardSubmit(null, false, true, false);
    nextCard();
  }, [handleCardSubmit, nextCard]);

  // check the card's skip condition, if it has one
  useEffect(() => {
    if (card?.automation?.skipCondition) {
      if (shouldSkip(card.automation.skipCondition, completedCardEvents)) {
        skip();
      }
    }
  }, [card, completedCardEvents, skip]);

  return (
    <ModuleContext.Provider
      value={{
        loadModule,
        module,
        moduleError: error,
        moduleLoading: loading,
        moduleProgress,
        card,
        completedCardEvents,
        nextCard,
        handleCardSubmit,
        setCardIndex,
        displayMode,
        setDisplayMode,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};

// find the first card that hasn't been answered correctly, hasn't been skipped, or wasn't retryable
const startingIndex = (cards: CardEntity[], events: EventEntity[]) => {
  if (events.length === 0) {
    return 0;
  }

  const idx = cards.findIndex((card) => {
    return !events.find((event) => {
      return (
        event.cardId === card.id &&
        (event.correct || event.skip || !event.retryable)
      );
    });
  });
  if (idx === -1) {
    return cards.length; // all cards answered correctly
  }

  return idx;
};

// check if the skip condition is met by the completedCardEvents
// TODO: add unit tests
const shouldSkip = (
  skipCondition: SkipCondition,
  completedCardEvents?: IngestEventRequest[],
) => {
  if (!skipCondition.card?.id) {
    return false;
  }

  if (!completedCardEvents || completedCardEvents.length === 0) {
    return false;
  }

  const relevantEvent = completedCardEvents.find(
    (event) => skipCondition.card && event.cardId === skipCondition.card.id,
  );

  if (!relevantEvent) {
    return false;
  }

  // detect if the previous card was skipped
  if (skipCondition.test === "wasSkipped") {
    return !!relevantEvent.skip; // true if skipped, false otherwise
  }

  // checkbox
  if (relevantEvent.answer?.checkboxIDs) {
    switch (skipCondition.test) {
      case "includes":
        return relevantEvent.answer.checkboxIDs.includes(
          skipCondition.answerID,
        );
      case "excludes":
        return !relevantEvent.answer.checkboxIDs.includes(
          skipCondition.answerID,
        );
      case "equals":
        return (
          relevantEvent.answer.checkboxIDs.length === 1 &&
          relevantEvent.answer.checkboxIDs[0] === skipCondition.answerID
        );
      default:
        return false;
    }
  }

  // radio
  if (relevantEvent.answer?.radioID) {
    switch (skipCondition.test) {
      case "includes":
      case "equals":
        return relevantEvent.answer.radioID === skipCondition.answerID;
      case "excludes":
        return relevantEvent.answer.radioID !== skipCondition.answerID;
      default:
        return false;
    }
  }

  return false;
};
